<template>
	<el-dialog :close-on-click-modal="false" append-to-body title="我的钱包" :visible.sync="mobileVisible" center width="600px" :before-close="handleClose">
		<el-table :data="tableData" v-loading="loading" stripe>
			<el-table-column prop="agent_name" label="供应商名称" show-overflow-tooltip />
			<el-table-column prop="balance" label="余额" width="200" show-overflow-tooltip />
		</el-table>
	</el-dialog>
</template>
<script>
	import {
		getAgentBalanceList
	} from '@/api/system/index'
	export default {
		data() {
			return {
				mobileVisible: false,
				tableData: [],
				loading: false
			}
		},
		methods: {
			// 打开弹窗
			handleOpen() {
				this.mobileVisible = true
				this.loading = true
				getAgentBalanceList().then(res => {
					if(res.code == '0000') {
						this.tableData = res.data
					}
					this.loading = false
				})
			},
			// 关闭弹窗
			handleClose() {
				this.mobileVisible = false
			}
		},
	}
</script>
<style lang="scss" scoped>
	::v-deep .el-dialog__body{
		padding: 20px 30px !important;
	}
</style>