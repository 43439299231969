<template>
	<div :class="isZoom?'qs-logo':'qs-logo qs-logo-none'">
		<router-link :class="isZoom?'qs-login-text':'qs-login-text qs-login-text-white'" to="/">{{ platformName }}</router-link>
	</div>
</template>
<script>
	import {
		mapState
	} from 'vuex';
	export default {
		name: 'logo',
		computed: {
			...mapState({
				platformName: (state) => state.common.platformName,
				isZoom: (state) => state.layout.isZoom
			})
		}
	}
</script>
<style lang="scss" scoped>
	.qs-logo {
		width: 235px;
		height: 68px;
		background: $headBgColor;
		color: $themeTextColor;
		position: absolute;
		left: 0;
		bottom: 0;
		right: 0;
		top: 0;
		cursor: pointer;
		transition: all 0.5s;
		box-shadow: 4px 0px 10px 0px rgba(78, 89, 105, 0.06);

		&.qs-logo-none {
			position: absolute;
			left: -235px;
		}

		.qs-login-text {
			display: block;
			width: 100%;
			height: 100%;
			line-height: 68px;
			padding-left: 66px;
			color: $levelColor1;
			font-weight: bold;
			font-size: 20px;
			background: #fff url('../../../assets/images/navMenuLeft/webIcon.png') 25px center no-repeat;
			background-size: 30px 30px;
			// &.qs-login-text-white {
			// 	display: none;
			// 	color: $menuBgColor;
			// 	background: #fff;
			// }
		}
	}
</style>