<template>
    <div class="qs-empty-box">
        <div class="qs-empty-item">
            <img class="qs-empty-img" src="@/assets/images/linkExpired.png" alt="">
            <div class="qs-empty-text">暂无数据</div>
        </div>
        <!-- <div v-if="type=='search'">
            搜索没有数据
        </div> -->
    </div>
</template>

<script>
export default {
    data() {
        return {
            type: 'initData',
        }
    },
    methods: {
        initType(data) {
            this.type = data
        },
    },
}
</script>

<style lang="scss" scoped>
.qs-empty-box {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
	padding-top: 70px;
    .qs-empty-item {
        position: relative;
        width: 230px;
        height: 210px;
        .qs-empty-img {
            display: block;
            width: 100%;
            height: 100%;
        }
        .qs-empty-text {
            width: 100%;
            text-align: center;
            font-size: 14px;
            color: #999;
            position: absolute;
            bottom: 25px;
            left: 0;
            letter-spacing: 1px;
        }
    }
}
</style>
