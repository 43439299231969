import router from '../router/index.js';
import {
	removeToken
} from '@/utils/auth';
import {
	Message,
	MessageBox
} from 'element-ui'
import store from '@/store/index.js';
let isMessageBoxShowing = false;
export function handlerHttpError(res) {
	switch (res.code) {
		case '3000':
			if (isMessageBoxShowing) {
				return; // 如果已经有MessageBox在显示，则直接返回
			}
			isMessageBoxShowing = true;
			MessageBox.confirm(res.msg, '提示', {
				confirmButtonText: '重新登录',
				cancelButtonText: '', // 空字符串隐藏取消按钮
				showCancelButton: false, // 设置为 false 隐藏取消按钮
				type: 'warning'
			}).then(() => {
				store.commit('common/SET_OPTIONS_ISLOGINOUT', true);
				removeToken();
				router.push('/login');
				return
			}).catch(() => {
				// 用户点击了取消按钮或关闭了对话框
			}).finally(() => {
				isMessageBoxShowing = false; // 重置标志位
			});
			break;
		case '4000':
			Message.error(res.msg)
			break;
		case '3100':
			Message.error(res.msg)
			break;
		case '3200':
			// Message.error(res.msg)
			break;
		case '5000':
			Message.error(res.msg)
			break;
		default:
			// if (res.msg) {
			// 	Message.error(res.msg)
			// }
			break
	}
}