import layout from '@/layout/index.vue'
const opration = {
	path: '/operation',
	component: layout,
	name: 'operation',
	redirect: '/operation/dataSummary',
	meta: {
		title: '工作台',
		isSideBar: true
	},
	children: [{
			path: '/operation/dataSummary',
			component: () => import('@/views/operation/dataSummary/index.vue'),
			name: 'dataSummary',
			redirect: '/operation/dataSummary/dataCenter',
			isShow: true,
			meta: {
				title: '数据概览',
				iconName: 'shuju'
			},
			children: [{
				path: '/operation/dataSummary/dataCenter',
				component: () => import('@/views/operation/dataSummary/dataCenter/index.vue'),
				name: 'dataCenter',
				redirect: '/operation/dataSummary/dataCenter/list',
				meta: {
					title: '数据中心'
				},
				children: [{
					path: '/operation/dataSummary/dataCenter/list',
					component: () => import('@/views/operation/dataSummary/dataCenter/list.vue'),
					name: 'dataSummaryIndex',
					meta: {
						title: '数据中心',
						isShow: true,
						activeMenu: '/operation/dataSummary/dataCenter'
					},
				}]
			}]
		}, {
			path: '/operation/resource',
			component: () => import('@/views/operation/resource/index.vue'),
			name: 'operationClassify',
			isShow: true,
			redirect: '/operation/resource/voiceCommunication',
			meta: {
				title: '语音通信',
				iconName: 'yytongxin'
			},
			children: [{
				path: '/operation/resource/voiceCommunication',
				component: () => import('@/views/operation/resource/voiceCommunication/index.vue'),
				name: 'voiceCommunication',
				redirect: '/operation/resource/voiceCommunication/list',
				meta: {
					title: '资源管理'
				},
				children: [{
					path: '/operation/resource/voiceCommunication/list',
					component: () => import('@/views/operation/resource/voiceCommunication/list.vue'),
					name: 'resourceManagementList',
					meta: {
						title: '资源管理',
						isShow: true,
						activeMenu: '/operation/resource/voiceCommunication'
					},
				}, {
					path: '/operation/resource/voiceCommunication/add',
					component: () => import('@/views/operation/resource/voiceCommunication/add.vue'),
					name: 'resourceManagementAdd',
					meta: {
						title: '新增资源',
						isShow: true,
						activeMenu: '/operation/resource/voiceCommunication'
					},
				}, {
					path: '/operation/resource/voiceCommunication/detail',
					component: () => import('@/views/operation/resource/voiceCommunication/detail.vue'),
					name: 'resourceManagementDetail',
					meta: {
						title: '资源详情',
						isShow: true,
						activeMenu: '/operation/resource/voiceCommunication'
					},
				}, {
					path: '/operation/resource/voiceCommunication/edit',
					component: () => import('@/views/operation/resource/voiceCommunication/edit.vue'),
					name: 'resourceManagementEdit',
					meta: {
						title: '编辑资源',
						isShow: true,
						activeMenu: '/operation/resource/voiceCommunication'
					},
				}, ]
			}, {
				path: '/operation/resource/distributeResource',
				component: () => import('@/views/operation/resource/distributeResource/index.vue'),
				name: 'distributeResource',
				redirect: '/operation/resource/distributeResource/list',
				meta: {
					title: '已分配资源'
				},
				children: [{
					path: '/operation/resource/distributeResource/list',
					component: () => import('@/views/operation/resource/distributeResource/list.vue'),
					name: 'distributeResourceList',
					meta: {
						title: '已分配资源',
						isShow: true,
						activeMenu: '/operation/resource/distributeResource'
					},
				}]
			}, ]
		}, {
			path: '/operation/numberManagement',
			component: () => import('@/views/operation/numberManagement/index.vue'),
			name: 'numberManagement',
			isShow: true,
			meta: {
				title: '工作号管理',
				iconName: 'worknum'
			},
			children: [{
				path: '/operation/numberManagement/numCompany',
				component: () => import('@/views/operation/numberManagement/numCompany/index.vue'),
				name: 'numCompany',
				redirect: '/operation/numberManagement/numCompany/list',
				meta: {
					title: '工作号企业'
				},
				children: [{
					path: '/operation/numberManagement/numCompany/list',
					component: () => import('@/views/operation/numberManagement/numCompany/list.vue'),
					name: 'numCompanyList',
					meta: {
						title: '工作号企业',
						isShow: true,
						activeMenu: '/operation/numberManagement/numCompany'
					},
				}]
			}, {
				path: '/operation/numberManagement/allNumber',
				component: () => import('@/views/operation/numberManagement/allNumber/index.vue'),
				name: 'numberManagementAll',
				redirect: '/operation/numberManagement/allNumber/list',
				meta: {
					title: '号码池'
				},
				children: [{
					path: '/operation/numberManagement/allNumber/list',
					component: () => import('@/views/operation/numberManagement/allNumber/list.vue'),
					name: 'allNumber',
					meta: {
						title: '号码池',
						isShow: true,
						activeMenu: '/operation/numberManagement/allNumber'
					},
				}]
			}, {
				path: '/operation/numberManagement/workNumAssigned',
				component: () => import('@/views/operation/numberManagement/workNumAssigned/index.vue'),
				name: 'workNumAssigned',
				redirect: '/operation/numberManagement/workNumAssigned/list',
				meta: {
					title: '已分配号码'
				},
				children: [{
					path: '/operation/numberManagement/workNumAssigned/list',
					component: () => import('@/views/operation/numberManagement/workNumAssigned/list.vue'),
					name: 'workNumAssignedList',
					meta: {
						title: '已分配号码',
						isShow: true,
						activeMenu: '/operation/numberManagement/workNumAssigned'
					},
				}]
			}, {
				path: '/operation/numberManagement/workNumMarked',
				component: () => import('@/views/operation/numberManagement/workNumMarked/index.vue'),
				name: 'workNum',
				redirect: '/operation/numberManagement/workNumMarked/list',
				meta: {
					title: '待退订号码'
				},
				children: [{
					path: '/operation/numberManagement/workNumMarked/list',
					component: () => import('@/views/operation/numberManagement/workNumMarked/list.vue'),
					name: 'workNumList',
					meta: {
						title: '待退订号码',
						isShow: true,
						activeMenu: '/operation/numberManagement/workNumMarked'
					},
				}]
			}, {
				path: '/operation/numberManagement/workNumRecycle',
				component: () => import('@/views/operation/numberManagement/workNumRecycle/index.vue'),
				name: 'workNumRecycle',
				redirect: '/operation/numberManagement/workNumRecycle/list',
				meta: {
					title: '回收站'
				},
				children: [{
					path: '/operation/numberManagement/workNumRecycle/list',
					component: () => import('@/views/operation/numberManagement/workNumRecycle/list.vue'),
					name: 'workNumRecycleList',
					meta: {
						title: '回收站',
						isShow: true,
						activeMenu: '/operation/numberManagement/workNumRecycle'
					},
				}]
			}]
		}, {
			path: '/operation/voicePoolManagement',
			component: () => import('@/views/operation/voicePoolManagement/index.vue'),
			name: 'voicePoolManagement',
			isShow: true,
			meta: {
				title: '语音池管理',
				iconName: 'yuyinchi'
			},
			children: [{
				path: '/operation/voicePoolManagement/openedNum',
				component: () => import('@/views/operation/voicePoolManagement/openedNum/index.vue'),
				name: 'openedNum',
				redirect: '/operation/voicePoolManagement/openedNum/list',
				meta: {
					title: '已开通号码'
				},
				children: [{
					path: '/operation/voicePoolManagement/openedNum/list',
					component: () => import('@/views/operation/voicePoolManagement/openedNum/list.vue'),
					name: 'openedNumList',
					meta: {
						title: '已开通号码',
						isShow: true,
						activeMenu: '/operation/voicePoolManagement/openedNum'
					},
				}]
			}, {
				path: '/operation/voicePoolManagement/unsubscribeNum',
				component: () => import('@/views/operation/voicePoolManagement/unsubscribeNum/index.vue'),
				name: 'unsubscribeNum',
				redirect: '/operation/voicePoolManagement/unsubscribeNum/list',
				meta: {
					title: '已退订号码'
				},
				children: [{
					path: '/operation/voicePoolManagement/unsubscribeNum/list',
					component: () => import('@/views/operation/voicePoolManagement/unsubscribeNum/list.vue'),
					name: 'unsubscribeNumList',
					meta: {
						title: '已退订号码',
						isShow: true,
						activeMenu: '/operation/voicePoolManagement/unsubscribeNum'
					},
				}]
			}]
		}, {
			path: '/operation/ringbackNumber',
			component: () => import('@/views/operation/ringbackNumber/index.vue'),
			name: 'ringbackNumber',
			isShow: true,
			meta: {
				title: '回铃号管理',
				iconName: 'huilinghao'
			},
			children: [{
				path: '/operation/ringbackNumber/allNumber',
				component: () => import('@/views/operation/ringbackNumber/allNumber/index.vue'),
				name: 'ringbackNumberAll',
				redirect: '/operation/ringbackNumber/allNumber/list',
				meta: {
					title: '号码池'
				},
				children: [{
					path: '/operation/ringbackNumber/allNumber/list',
					component: () => import('@/views/operation/ringbackNumber/allNumber/list.vue'),
					name: 'ringbackNumberAllList',
					meta: {
						title: '号码池',
						isShow: true,
						activeMenu: '/operation/ringbackNumber/allNumber'
					},
				}]
			}, {
				path: '/operation/ringbackNumber/assigned',
				component: () => import('@/views/operation/ringbackNumber/assigned/index.vue'),
				name: 'assignedNum',
				redirect: '/operation/ringbackNumber/assigned/list',
				meta: {
					title: '已分配号码'
				},
				children: [{
					path: '/operation/ringbackNumber/assigned/list',
					component: () => import('@/views/operation/ringbackNumber/assigned/list.vue'),
					name: 'assignedNumList',
					meta: {
						title: '已分配号码',
						isShow: true,
						activeMenu: '/operation/ringbackNumber/assigned'
					},
				}]
			}, {
				path: '/operation/ringbackNumber/marked',
				component: () => import('@/views/operation/ringbackNumber/marked/index.vue'),
				name: 'markedNum',
				redirect: '/operation/ringbackNumber/marked/list',
				meta: {
					title: '待退订号码'
				},
				children: [{
					path: '/operation/ringbackNumber/marked/list',
					component: () => import('@/views/operation/ringbackNumber/marked/list.vue'),
					name: 'markedNumList',
					meta: {
						title: '待退订号码',
						isShow: true,
						activeMenu: '/operation/ringbackNumber/marked'
					},
				}]
			}, {
				path: '/operation/ringbackNumber/recycle',
				component: () => import('@/views/operation/ringbackNumber/recycle/index.vue'),
				name: 'recycle',
				redirect: '/operation/ringbackNumber/recycle/list',
				meta: {
					title: '回收站'
				},
				children: [{
					path: '/operation/ringbackNumber/recycle/list',
					component: () => import('@/views/operation/ringbackNumber/recycle/list.vue'),
					name: 'recycleList',
					meta: {
						title: '回收站',
						isShow: true,
						activeMenu: '/operation/ringbackNumber/recycle'
					},
				}]
			}]
		}, {
			path: '/operation/supplierManagement',
			component: () => import('@/views/operation/supplierManagement/index.vue'),
			name: 'supplierManagement',
			isShow: true,
			meta: {
				title: '供应商管理',
				iconName: 'supplier'
			},
			children: [{
				path: '/operation/supplierManagement/supplierList',
				component: () => import('@/views/operation/supplierManagement/supplier/index.vue'),
				name: 'supplierAll',
				redirect: '/operation/supplierManagement/supplier/list',
				meta: {
					title: '供应商管理'
				},
				children: [{
					path: '/operation/supplierManagement/supplier/list',
					component: () => import('@/views/operation/supplierManagement/supplier/list.vue'),
					name: 'supplierList',
					meta: {
						title: '供应商管理',
						isShow: true,
						activeMenu: '/operation/supplierManagement/supplierList'
					},
				}]
			}]
		}, {
			path: '/operation/channelManagement',
			component: () => import('@/views/operation/channelManagement/index.vue'),
			name: 'channelManagement',
			isShow: true,
			meta: {
				title: '渠道管理',
				iconName: 'channel'
			},
			children: [{
				path: '/operation/channelManagement/channelList',
				component: () => import('@/views/operation/channelManagement/channel/index.vue'),
				name: 'channelAll',
				redirect: '/operation/channelManagement/channel/list',
				meta: {
					title: '渠道管理'
				},
				children: [{
					path: '/operation/channelManagement/channel/list',
					component: () => import('@/views/operation/channelManagement/channel/list.vue'),
					name: 'channelList',
					meta: {
						title: '渠道管理',
						isShow: true,
						activeMenu: '/operation/channelManagement/channelList'
					},
				}]
			}]
		}, {
			path: '/operation/companyManagement',
			component: () => import('@/views/operation/companyManagement/index.vue'),
			name: 'companyManagement',
			isShow: true,
			meta: {
				title: '企业管理',
				iconName: 'company'
			},
			children: [{
				path: '/operation/companyManagement/companyList',
				component: () => import('@/views/operation/companyManagement/company/index.vue'),
				name: 'companyReportAll',
				redirect: '/operation/companyManagement/company/list',
				meta: {
					title: '报备企业'
				},
				children: [{
					path: '/operation/companyManagement/company/list',
					component: () => import('@/views/operation/companyManagement/company/list.vue'),
					name: 'companyList',
					meta: {
						title: '报备企业',
						isShow: true,
						activeMenu: '/operation/companyManagement/companyList'
					},
				}, {
					path: '/operation/companyManagement/company/addReport',
					component: () => import('@/views/operation/companyManagement/company/addReport.vue'),
					name: 'companyAddReport',
					meta: {
						title: '新增报备企业',
						isShow: true,
						activeMenu: '/operation/companyManagement/companyAddReport'
					},
				}, {
					path: '/operation/companyManagement/company/reportComInfo',
					component: () => import('@/views/operation/companyManagement/company/reportComInfo.vue'),
					name: 'companyReportComInfo',
					meta: {
						title: '企业详情',
						isShow: true,
						activeMenu: '/operation/companyManagement/companyReportComInfo'
					},
				}, {
					path: '/operation/companyManagement/company/editReport',
					component: () => import('@/views/operation/companyManagement/company/editReport.vue'),
					name: 'companyEditReport',
					meta: {
						title: '编辑报备企业',
						isShow: true,
						activeMenu: '/operation/companyManagement/companyEditReport'
					},
				}, {
					path: '/operation/companyManagement/company/add',
					component: () => import('@/views/operation/companyManagement/company/add.vue'),
					name: 'companyAdd',
					meta: {
						title: '新增企业',
						isShow: true,
						activeMenu: '/operation/companyManagement/companyAdd'
					},
				}, {
					path: '/operation/companyManagement/company/edit',
					component: () => import('@/views/operation/companyManagement/company/edit.vue'),
					name: 'companyEdit',
					meta: {
						title: '编辑企业',
						isShow: true,
						activeMenu: '/operation/companyManagement/companyEdit'
					},
				}, {
					path: '/operation/companyManagement/company/detail',
					component: () => import('@/views/operation/companyManagement/company/detail.vue'),
					name: 'companyDetail',
					meta: {
						title: '企业详情',
						isShow: true,
						activeMenu: '/operation/companyManagement/companyDetail'
					},
				}]
			}, {
				path: '/operation/companyManagement/agentComlist',
				component: () => import('@/views/operation/companyManagement/company/agentComlist.vue'),
				name: 'companyAgentAll',
				redirect: '/operation/companyManagement/company/agentComlist',
				meta: {
					title: '渠道企业'
				},
				children: [{
					path: '/operation/companyManagement/company/agentComlist',
					component: () => import('@/views/operation/companyManagement/company/agentComlist.vue'),
					name: 'agentComlist',
					meta: {
						title: '渠道企业',
						isShow: true,
						activeMenu: '/operation/companyManagement/agentComlist'
					},
				}]
			}, {
				path: '/operation/companyManagement/companyCallerList',
				component: () => import('@/views/operation/companyManagement/company/companyCallerList.vue'),
				name: 'companyCallerAll',
				redirect: '/operation/companyManagement/company/companyCallerList',
				meta: {
					title: '主叫管理'
				},
				children: [{
					path: '/operation/companyManagement/company/companyCallerList',
					component: () => import('@/views/operation/companyManagement/company/companyCallerList.vue'),
					name: 'companyCallerList',
					meta: {
						title: '主叫管理',
						isShow: true,
						activeMenu: '/operation/companyManagement/companyCallerList'
					},
				}]
			}]
		}, {
			path: '/operation/financeManagement',
			component: () => import('@/views/operation/financeManagement/index.vue'),
			name: 'financeManagement',
			isShow: true,
			meta: {
				title: '财务管理',
				iconName: 'financeManage'
			},
			children: [{
				path: '/operation/financeManagement/platformBalanceList',
				component: () => import('@/views/operation/financeManagement/platformBalance/index.vue'),
				name: 'platformBalanceAll',
				redirect: '/operation/financeManagement/platformBalance/list',
				meta: {
					title: '平台余额流水明细'
				},
				children: [{
					path: '/operation/financeManagement/platformBalance/list',
					component: () => import('@/views/operation/financeManagement/platformBalance/list.vue'),
					name: 'platformBalance',
					meta: {
						title: '平台余额流水明细',
						isShow: true,
						activeMenu: '/operation/financeManagement/platformBalanceList'
					},
				}]
			}, {
				path: '/operation/financeManagement/supplierBalanceList',
				component: () => import('@/views/operation/financeManagement/supplierBalance/index.vue'),
				name: 'supplierBalanceAll',
				redirect: '/operation/financeManagement/supplierBalance/list',
				meta: {
					title: '供应商余额流水明细'
				},
				children: [{
					path: '/operation/financeManagement/supplierBalance/list',
					component: () => import('@/views/operation/financeManagement/supplierBalance/list.vue'),
					name: 'supplierBalance',
					meta: {
						title: '供应商余额流水明细',
						isShow: true,
						activeMenu: '/operation/financeManagement/supplierBalanceList'
					},
				}]
			}, {
				path: '/operation/financeManagement/myAccountBalance',
				component: () => import('@/views/operation/financeManagement/myAccountBalance/index.vue'),
				name: 'myAccountBalanceAll',
				redirect: '/operation/financeManagement/myAccountBalance/list',
				meta: {
					title: '我的余额流水明细'
				},
				children: [{
					path: '/operation/financeManagement/myAccountBalance/list',
					component: () => import('@/views/operation/financeManagement/myAccountBalance/list.vue'),
					name: 'myAccountBalance',
					meta: {
						title: '我的余额流水明细',
						isShow: true,
						activeMenu: '/operation/financeManagement/myAccountBalance'
					},
				}]
			}, {
				path: '/operation/financeManagement/agentBalanceList',
				component: () => import('@/views/operation/financeManagement/agentBalance/index.vue'),
				name: 'agentBalanceAll',
				redirect: '/operation/financeManagement/agentBalance/list',
				meta: {
					title: '渠道余额流水明细'
				},
				children: [{
					path: '/operation/financeManagement/agentBalance/list',
					component: () => import('@/views/operation/financeManagement/agentBalance/list.vue'),
					name: 'agentBalance',
					meta: {
						title: '渠道余额流水明细',
						isShow: true,
						activeMenu: '/operation/financeManagement/agentBalanceList'
					},
				}]
			}]
		}, {
			path: '/operation/cdrManagement',
			component: () => import('@/views/operation/cdrManagement/index.vue'),
			name: 'cdrManagement',
			isShow: true,
			meta: {
				title: '话单管理',
				iconName: 'cdrManage'
			},
			children: [{
				path: '/operation/cdrManagement/cdrList',
				component: () => import('@/views/operation/cdrManagement/cdr/index.vue'),
				name: 'cdrAll',
				redirect: '/operation/cdrManagement/cdr/list',
				meta: {
					title: '话单管理'
				},
				children: [{
					path: '/operation/cdrManagement/cdr/list',
					component: () => import('@/views/operation/cdrManagement/cdr/list.vue'),
					name: 'cdrList',
					meta: {
						title: '话单管理',
						isShow: true,
						activeMenu: '/operation/cdrManagement/cdrList'
					},
				}]
			}]
		}, {
			path: '/operation/reportingMaterials',
			component: () => import('@/views/operation/reportingMaterials/index.vue'),
			name: 'reportingMaterials',
			isShow: true,
			meta: {
				title: '报备材料',
				iconName: 'baobei'
			},
			children: [{
				path: '/operation/reportingMaterials/templatelist',
				component: () => import('@/views/operation/reportingMaterials/template/index.vue'),
				name: 'templateAll',
				redirect: '/operation/reportingMaterials/template/list',
				meta: {
					title: '材料模板'
				},
				children: [{
					path: '/operation/reportingMaterials/template/list',
					component: () => import('@/views/operation/reportingMaterials/template/list.vue'),
					name: 'templateList',
					meta: {
						title: '材料模板',
						isShow: true,
						activeMenu: '/operation/reportingMaterials/templatelist'
					},
				}]
			}]
		},
		// {
		// 	path: '/operation/reportMaterials',
		// 	component: () => import('@/views/operation/reportMaterials/index.vue'),
		// 	name: 'reportMaterials',
		// 	isShow: true,
		// 	meta: {
		// 		title: '报备材料管理',
		// 		iconName: 'cdrManage'
		// 	},
		// 	children: [{
		// 		path: '/operation/reportMaterials/materialTemplate',
		// 		component: () => import('@/views/operation/reportMaterials/template/index.vue'),
		// 		name: 'materialTemplate',
		// 		redirect: '/operation/reportMaterials/template/list',
		// 		meta: {
		// 			title: '材料模板'
		// 		},
		// 		children: [{
		// 			path: '/operation/reportMaterials/template/list',
		// 			component: () => import('@/views/operation/reportMaterials/template/list.vue'),
		// 			name: 'templateList',
		// 			meta: {
		// 				title: '材料模板',
		// 				isShow: true,
		// 				activeMenu: '/operation/reportMaterials/templateList'
		// 			},
		// 		}]
		// 	}]
		// }, 
		{
			path: '/operation/systemManagement',
			component: () => import('@/views/operation/systemManagement/index.vue'),
			name: 'systemManagement',
			isShow: true,
			meta: {
				title: '系统管理',
				iconName: 'systemManage'
			},
			children: [{
				path: '/operation/systemManagement/userList',
				component: () => import('@/views/operation/systemManagement/user/index.vue'),
				name: 'userManage',
				redirect: '/operation/systemManagement/user/list',
				meta: {
					title: '用户管理'
				},
				children: [{
					path: '/operation/systemManagement/user/list',
					component: () => import('@/views/operation/systemManagement/user/list.vue'),
					name: 'userList',
					meta: {
						title: '用户管理',
						isShow: true,
						activeMenu: '/operation/systemManagement/userList'
					},
				}]
			}, 
			// {
			// 	path: '/operation/systemManagement/roleList',
			// 	component: () => import('@/views/operation/systemManagement/role/index.vue'),
			// 	name: 'roleManage',
			// 	redirect: '/operation/systemManagement/role/list',
			// 	meta: {
			// 		title: '角色管理'
			// 	},
			// 	children: [{
			// 		path: '/operation/systemManagement/role/list',
			// 		component: () => import('@/views/operation/systemManagement/role/list.vue'),
			// 		name: 'roleList',
			// 		meta: {
			// 			title: '角色管理',
			// 			isShow: true,
			// 			activeMenu: '/operation/systemManagement/roleList'
			// 		},
			// 	}]
			// }, 
			{
				path: '/operation/systemManagement/logsList',
				component: () => import('@/views/operation/systemManagement/logs/index.vue'),
				name: 'companyAll',
				redirect: '/operation/systemManagement/logs/list',
				meta: {
					title: '日志管理'
				},
				children: [{
					path: '/operation/systemManagement/logs/list',
					component: () => import('@/views/operation/systemManagement/logs/list.vue'),
					name: 'logsList',
					meta: {
						title: '日志管理',
						isShow: true,
						activeMenu: '/operation/systemManagement/logsList'
					},
				}]
			}, {
				path: '/operation/systemManagement/developConfig',
				component: () => import('@/views/operation/systemManagement/developConfig/index.vue'),
				name: 'developConfig',
				redirect: '/operation/systemManagement/developConfig/config',
				meta: {
					title: '开发者配置'
				},
				children: [{
					path: '/operation/systemManagement/developConfig/config',
					component: () => import('@/views/operation/systemManagement/developConfig/config.vue'),
					name: 'config',
					meta: {
						title: '开发者配置',
						isShow: true,
						activeMenu: '/operation/systemManagement/developConfig'
					},
				}]
			}]
		}
	]
}
export default opration