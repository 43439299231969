<template>
	<div class="qs-menu-wrapper">
		<ul class="qs-menu-ul clearfix">
			<li :class="isZoom ? 'qs-zoom' : 'qs-zoom-ac'" @click="_debounce(handleIsZoom, 500)"></li>
		</ul>
		<div class="notice-box" v-if="notices.length">
			<div class="notice-icon">系统公告<el-divider class="divider" direction="vertical"></el-divider></div>
			<div class="headers-middle">
				<Notice :noticeData="notices"/>
			</div>
		</div>
	</div>
</template>
<script>
	import { getSystemNotice } from '@/api/public/index'
	import { mapState } from 'vuex'
	import Notice from '@/components/notice.vue';
	export default {
		name: 'topMenu',
		components: { Notice },
		data() {
			return {
				timer: null,
				notices: [],
			}
		},
		computed: {
			...mapState({
				isSideBar: (state) => state.layout.isSideBar,
				isZoom: (state) => state.layout.isZoom,
			}),
		},
		mounted() {
			getSystemNotice().then(res => {
				this.notices = res.data || []
			})
		},
		beforeDestroy() {
			clearInterval(this.timer);
		},
		methods: {
			handleIsZoom() {
				if (this.isSideBar) {// isSideBar false 不展示左侧菜单 true 展示左侧菜单
					this.SET_ISZOOM(!this.isZoom)
				}
			},
		},
	}
</script>
<style lang="scss" scoped>
	.qs-menu-wrapper {
		width: 50%;
		height: 68px;
		overflow-x: auto;
		display: flex;
		align-items: center;
		justify-content: start;

		&::-webkit-scrollbar {
			display: none;
		}

		.qs-menu-ul {
			white-space: nowrap;
			height: 60px;
			width: 50px;
			display: flex;
			align-items: center;
			justify-content: start;

			.qs-zoom {
				width: 18px;
				height: 16px;
				cursor: pointer;
				display: inline-block;
				background: url('@assets/images/layout/zoom_in_1.png') no-repeat center center;
				background-size: 100% 100%;
				margin: 0 15px;
			}

			.qs-zoom-ac {
				width: 18px;
				height: 16px;
				cursor: pointer;
				display: inline-block;
				margin: 0 15px;
				background: url('@assets/images/layout/zoom_out_1.png') no-repeat center center;
				background-size: 100% 100%;
			}
		}

		.notice-box {
			width: 80%;
			height: 60%;
			display: flex;
			align-items: center;
			justify-content: start;
			margin: 0 0 0 12px;
			border-radius: 8px 8px 8px 8px;
			background: #EBF0FF;
			position: relative;
			font-size: 14px;
			cursor: default;
			user-select: none;

			.notice-icon {
				width: 115px;
				height: 36px;
				line-height: 36px;
				color: #2469FF;
				background: url('@/assets/images/layout/notice.png') 13px center no-repeat;
				background-size: 15%;
				padding-left: 36px;
				font-size: 14px;
				font-weight: bold;
				
				.divider {
					width: 2px;
				}
			}

			.headers-middle {
				position: absolute;
				left: 102px;
				top: 10px;
				width: 82%;
			}
		}
	}
</style>