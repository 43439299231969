import vue from 'vue'
//获取地区
function getAreaList(data) {
	return vue.$get('/agent/publics/getAreaList', data)
}
// //获取供应商下拉列表
function getSupplierList(data) {
	return vue.$get('/agent/publics/getSupplierList', data)
}
// //获取报备材料模板下拉
function getProfileTemplateList(data) {
	return vue.$get('/agent/publics/getProfileTemplateList', data)
}
//获取渠道下拉
function getAgentList(data) {
	return vue.$get('/agent/publics/getAgentList', data)
}
//获取本地线路下拉
function getLocalLineList(data) {
	return vue.$get('/agent/publics/getAreaList', data)
}
//获取企业下拉
function getCompanyListSelect(data) {
	return vue.$get('/agent/publics/getCompanyList', data)
}
//获取我的企业下拉 
function getMyCompanyList(data) {
	return vue.$get('/agent/publics/getMyCompanyList', data)
}
//获取渠道企业下拉 
function getAgentCompanyList(data) {
	return vue.$get('/agent/publics/getAgentCompanyList', data)
}
// 获取号码运营商下拉 
function getNumberOperatorList(data) {
	return vue.$get('/agent/publics/getNumberOperatorList', data)
}
//获取号码行内分配的企业下拉
function getNumberCompanyList(data) {
	return vue.$get('/agent/publics/getNumberCompanyList', data)
}
//获取行业下拉
function getIndustryList(data) {
	return vue.$get('/agent/publics/getIndustryList', data)
}
//获取材料模板字段下拉
// function getProfileFieldList(data) {
// 	return vue.$get('/agent/publics/getProfileFieldList', data)
// }
//获取呼叫模式下拉  
function getCallModeList(data) {
	return vue.$get('/agent/publics/getCallModeList', data)
}
//获取资源下拉  
function getResourceList(data) {
	return vue.$get('/agent/publics/getResourceList', data)
}
//获取我的资源下拉  
function getMyResourceList(data) {
	return vue.$get('/agent/publics/getMyResourceList', data)
}
//获取渠道资源下拉  
function getAgentResourceList(data) {
	return vue.$get('/agent/publics/getAgentResourceList', data)
}
// 获取角色下拉 
function getRoleList(data) {
	return vue.$get('/agent/publics/getRoleList', data)
}
//下载模板 
// function downTemplate (data) {
//     return vue.$get('/agent/publics/downTemplate', data)
// }
function downTemplate(data) {
	return vue.$download('get', '/agent/publics/downTemplate', data)
}
// 上传图片
function uploadImg(data) {
	return vue.$postFormData('/agent/upload/image', data)
}
// 上传文件
function uploadFile(data) {
	return vue.$postFormData('/agent/upload/file', data)
}
// 导入已开通号码
function importOpenedNum(data) {
	return vue.$postFormData('/agent/direct/import', data)
}
// 获取我的语音池的供应商
function getDirectSupplierList(data) {
	return vue.$get('/agent/publics/getDirectSupplierList', data)
}
//获取系统公告
function getSystemNotice(data) {
	return vue.$get('/agent/publics/getSystemNotice', data)
}
//获取供应商配置
function getSupplierConfig(data) {
	return vue.$get('/agent/publics/getSupplierConfig', data)
}
export {
	getAreaList,
	getSupplierList,
	getProfileTemplateList,
	getAgentList,
	getLocalLineList,
	getCompanyListSelect,
	getMyCompanyList,
	getAgentCompanyList,
	getNumberOperatorList,
	getNumberCompanyList,
	getIndustryList,
	// getProfileFieldList,
	getCallModeList,
	getResourceList,
	getMyResourceList,
	getAgentResourceList,
	downTemplate,
	getRoleList,
	uploadImg,
	uploadFile,
	importOpenedNum,
	getDirectSupplierList,
	getSystemNotice,
	getSupplierConfig
}