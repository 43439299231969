import Vue from 'vue'
import VueRouter from 'vue-router'
import layout from '@/layout/index.vue'
Vue.use(VueRouter)
export const constantRoutes = [{
	path: '/',
	component: layout,
	redirect: '/operation',
	name: 'Dashboard',
	meta: {
		title: '工作台'
	}
}, {
	path: '/login',
	component: () => import('@/views/login/index'),
	name: 'login',
	meta: {
		title: '登录'
	}
}, {
	path: '/register',
	component: () => import('@/views/login/register'),
	name: 'register',
	meta: {
		title: '注册'
	}
}, {
	path: '/retrievePassword',
	component: () => import('@/views/login/retrievePassword'),
	name: 'retrievePassword',
	meta: {
		title: '找回密码'
	}
}, {
	path: '/404',
	component: () => import('@/views/404'),
	meta: {
		title: '404'
	}
}]
const createRouter = () => new VueRouter({
	mode: 'hash', // require service support
	base: process.env.BASE_URL,
	routes: constantRoutes
})
const router = createRouter()
export function resetRouter() {
	const newRouter = createRouter()
	router.matcher = newRouter.matcher // reset router
}
// 向 router 上添加一个，重新实例化 VueRouter 的方法
router.reloadRouter = function() {
	router.matcher = new VueRouter({
		routes: constantRoutes
	}).matcher
}
export default router