import Cookies from 'js-cookie'
const TokenKey = 'open10086_token'
const SelectCompanyStateKey = 'open10086_is_select_company'
const UserInfoKey = 'open10086_user'
const AgentInfoKey = 'agent_info'
// 缓存token信息
export function getToken() {
	return Cookies.get(TokenKey)
}
export function setToken(token) {
	return Cookies.set(TokenKey, token)
}
export function removeToken() {
	return Cookies.remove(TokenKey)
}
// 缓存是否出现选择企业弹窗
export function setSelectCompanyStateId(isSelect) {
	return localStorage.setItem(SelectCompanyStateKey, isSelect)
}
export function getSelectCompanyStateId() {
	return localStorage.getItem(SelectCompanyStateKey)
}
export function removeSelectCompanyStateId() {
	return localStorage.removeItem(SelectCompanyStateKey)
}
// 缓存用户信息
export function setUserInfo(userInfo) {
	return localStorage.setItem(UserInfoKey, userInfo)
}
export function getUserInfo() {
	return localStorage.getItem(UserInfoKey)
}
export function removeUserInfo() {
	return localStorage.removeItem(UserInfoKey)
}
// 缓存方法
export function setRequestData(key, val) {
	return localStorage.setItem(key, val)
}
export function getRequestData(key) {
	return localStorage.getItem(key)
}
export function removeRequestData(key) {
	return localStorage.removeItem(key)
}
// 缓存渠道信息
export function setAgentInfo(agentInfo) {
	return localStorage.setItem(AgentInfoKey, agentInfo)
}
export function getAgentInfo() {
	return localStorage.getItem(AgentInfoKey)
}
export function removeAgentInfo() {
	return localStorage.removeItem(AgentInfoKey)
}