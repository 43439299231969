import { getToken } from '@/utils/auth'
import Axios from 'axios'
import qs from 'qs'
import {
	getEnvPath
} from '@/utils/envPath'

const service = Axios.create({
    baseURL: getEnvPath() == 'agent.open10086.com' ? 'https://api.open10086.com' : 'http://dev.api.open10086.com',
    // baseURL: 'http://dev.api.open10086.com',
	invitationLinkURl: 'agent.open10086.com'// 邀请链接前缀
})
// 请求列表
const pendingRequest = new Map()
function generateReqKey (config) {
    const { method, url, params, data } = config
    return [method, url, qs.stringify(params), qs.stringify(data)].join(
        '&'
    )
}

function addPendingRequest (config) {
    const requestKey = generateReqKey(config)
    config.cancelToken =
        config.cancelToken ||
        new Axios.CancelToken((cancel) => {
            if (!pendingRequest.has(requestKey)) {
                pendingRequest.set(requestKey, cancel)
            }
        })
}

function removePendingRequest (config) {
    const requestKey = generateReqKey(config)

    if (pendingRequest.has(requestKey)) {
        const cancel = pendingRequest.get(requestKey)
        cancel(requestKey)
        pendingRequest.delete(requestKey)
    }
}

service.interceptors.request.use(
    config => {
        // if (token) {
        config.headers['token'] = getToken()
        // }

        removePendingRequest(config) // 检查是否存在重复请求，若存在则取消已发的请求

        addPendingRequest(config) // 把当前请求添加到pendingRequest对象中

        return config
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error)
    }
)

service.interceptors.response.use(
    (response) => {
        removePendingRequest(response.config) // 从pendingRequest对象中移除请求
        return response
    },
    (error) => {
        removePendingRequest(error.config || {}) // 从pendingRequest对象中移除请求
        if (Axios.isCancel(error)) {
            // // console.log('已取消的重复请求：' + error.message)
        } else {
            // 添加异常处理
        }
        return Promise.reject(error)
    }
)

export default service
