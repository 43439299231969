<!-- eslint-disable no-mixed-spaces-and-tabs -->
<template>
	<div class="qs-sidebar-wrapper" :class="{ 'qs-sidebar-ac': !isShowZoom }">
		<ul class="qs-sidebar-menu">
			<li class="qs-sidebar-menu-item" :class="{ 'displayNone': !item.isShow }" v-for="(item, index) in routes" :key="index">
				<div class="qs-sidebar-menu-title" :class="item.showLevel2 ? `menu-title-act menu-title-${item.meta.iconName}` : `menu-title-${item.meta.iconName}`" @click="toDo('showlevel2', item)">
					{{ item.meta.title }}
					<i :class="item.showLevel2 ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
				</div>
				<SidebarSubmenu v-if="item.showLevel2 && item.children && item.children.length" :children="item.children"></SidebarSubmenu>
			</li>
		</ul>
		<favorites ref="refFavorites" :favorites-type="editFavorites" :title="title"></favorites>
	</div>
</template>
<script>
	import {
		mapState,
		mapMutations,
		mapActions
	} from 'vuex'
	import SidebarSubmenu from '@/layout/components/sidebar/sidebarSubmenu.vue'
	import favorites from '@/components/favorites.vue'
	import {
		getLeftMenuStat
	} from '@/api/public/index'
	export default {
		name: 'sidebar',
		props: ['parentMenu'],
		data() {
			return {
				editFavorites: '',
				title: '',
			}
		},
		components: {
			SidebarSubmenu,
			favorites,
		},
		watch: {
			$route(newValue) {
				let currentRoute = newValue.path
			},
		},
		computed: {
			...mapState({
				addRouters: (state) => state.routingTable.addRouters,
				isSideBar: (state) => state.layout.isSideBar,
				isZoom: (state) => state.layout.isZoom,
				rootMenuId: (state) => state.layout.rootMenuId,
			}),
			isShowZoom() {
				let currentPath = this.parentMenu
				let addRouters = this.addRouters
				var isShow = false
				addRouters.forEach((item) => {
					if (item.path == currentPath) {
						isShow = item.meta.isSideBar
					}
				})
				this.SET_ISSIDEBAR(isShow)
				if (this.isSideBar) {
					// 有侧边栏
					// 如果侧边栏已经切换回去（不展示的情况） 值永远为false  侧边栏无法展示出来
					// 想要的效果 每次切换 重新展示侧边栏
					return this.isZoom
				} else {
					// 无侧边栏
					return false
				}
			},
			routes() {
				let routeList = [],
					route_num = {},
					currentRoute = this.currentRoute,
					routingTable = this.$store.state.routingTable
				const allMenu = routingTable.routers ? [...routingTable.routers] : [],
					is_open_direct_call = localStorage.getItem('is_open_direct_call')
				allMenu.forEach((res) => {
					if (res.path == this.parentMenu) {
						if (res.children) {
							res.children.forEach((child) => {
								// 根据登录返回权限 判断语音池管理是否显示
								if (child.name == 'voicePoolManagement' && is_open_direct_call == 1) {
									child.isShow = true
								} else if (child.name == 'voicePoolManagement' && is_open_direct_call == 0) {
									child.isShow = false
								}
								let is_first_agent = JSON.parse(localStorage.getItem('agent_info')).is_first_agent
								if (child.name == 'reportingMaterials' && is_first_agent == 1) {
									child.isShow = true
								} else if (child.name == 'reportingMaterials' && is_first_agent == 0) {
									child.isShow = false
								}
								if(is_first_agent != 1) {/* 判断是否为一级渠道 */
									if(child.name == 'numberManagement') {/* 工作号管理 =》工作号企业 && 回收站 */
										child.children.map(item => {
											if(item.name == 'numCompany' || item.name == 'workNumRecycle') {
												item.isShow = false
											}
										})
									}
									if(child.name == 'ringbackNumber') {/* 回铃号管理 =》回收站 */
										child.children.map(item => {
											if(item.name == 'recycle') {
												item.isShow = false
											}
										})
									}
									if(child.name == 'financeManagement') {/* 财务管理 =》平台余额流水明细 && 供应商余额流水明细 */
										child.children.map(item => {
											if(item.name == 'platformBalanceAll' || item.name == 'supplierBalanceAll') {
												item.isShow = false
											}
										})
									}
								}
								if (child.children && child.children.length > 0) {
									child.children.forEach((childs) => {
										for (let key in route_num) {
											if (childs.meta.mark == key) {
												this.$set(childs['meta'], 'num', route_num[key])
											}
										}
									})
								}
							})
							routeList = [...res.children]
						}
					}
				})
				return routeList
			},
			currentRoute() {
				return this.$route.meta.activeMenu ? this.$route.meta.activeMenu : this.$route.path
			},
		},
		created() {
			this.routes.map(item => {
				if (this.currentRoute.indexOf(item.path) != -1) {
					item.showLevel2 = true
				}
			})
			this.$forceUpdate()
		},
		methods: {
			...mapMutations({
				SET_ISSIDEBAR: 'layout/SET_ISSIDEBAR',
			}),
			...mapActions({
				getLeftMenuStatistics: 'routingTable/getLeftMenuStatistics',
			}),
			toDo(oprate, item) {
				switch (oprate) {
					case 'jump':
						this.$router.push(item.path)
						break
					case 'favoriteOprate':
						if (item.path == '/talent/favorites') {
							this.editFavorites = 'telentFavorites'
							this.title = '人才收藏夹'
						} else if (item.path == '/job/favorites') {
							this.editFavorites = 'jobFavorites'
							this.title = '职位收藏夹'
						}
						this.$refs.refFavorites.handleOpen('editFavorite', this.editFavorites, '')
						break
					case 'showlevel2':
						this.routes.map(items => {
							if (items.path == item.path) {
								items.showLevel2 = !items.showLevel2
							} else {
								items.showLevel2 = false
							}
						})
						this.$forceUpdate()
						break
					default:
						break
				}
			},
		},
	}
</script>
<style lang="scss" scoped>
	.qs-sidebar-wrapper {
		width: 235px;
		height: calc(100% - 70px);
		position: fixed;
		left: 0;
		top: 68px;
		transition: all 0.5s;

		&.qs-sidebar-ac {
			transform: translateX(-235px);
		}

		.qs-sidebar-menu {
			height: 100%;
			overflow-y: auto;
			overflow-x: hidden;
			background: $menuBgColor;

			.qs-sidebar-menu-item {
				.qs-sidebar-menu-title {
					white-space: nowrap;
					height: 46px;
					line-height: 50px;
					font-weight: bold;
					color: $levelColor1;
					font-size: 14px;
					cursor: pointer;
					display: flex;
					align-items: center;
					justify-content: space-between;
					box-sizing: border-box;
					padding: 0 24px 0 50px;

					.el-icon-arrow-up {
						color: $themeColor;
						font-weight: bold;
					}

					.el-icon-arrow-down {
						font-weight: bold;
					}

					&.menu-title-shuju {
						background: url('../../assets/images/navMenuLeft/shuju.png') 24px center no-repeat;
						background-size: 18px 18px;

						&:hover,
						&.menu-title-act {
							background: url('../../assets/images/navMenuLeft/shuju-sel.png') 24px center no-repeat;
							background-size: 18px 18px;
							color: #517FFD;
						}
					}

					&.menu-title-yytongxin {
						background: url('../../assets/images/navMenuLeft/yuyin.png') 24px center no-repeat;
						background-size: 18px 18px;

						&:hover,
						&.menu-title-act {
							background: url('../../assets/images/navMenuLeft/yuyin-sel.png') 24px center no-repeat;
							background-size: 18px 18px;
							color: #517FFD;
						}
					}

					&.menu-title-worknum {
						background: url('../../assets/images/navMenuLeft/gongzuohao.png') 24px center no-repeat;
						background-size: 18px 18px;

						&:hover,
						&.menu-title-act {
							background: url('../../assets/images/navMenuLeft/gongzuohao-sel.png') 24px center no-repeat;
							background-size: 18px 18px;
							color: #517FFD;
						}
					}

					&.menu-title-yuyinchi {
						background: url('../../assets/images/navMenuLeft/yuyinchi.png') 24px center no-repeat;
						background-size: 18px 18px;

						&:hover,
						&.menu-title-act {
							background: url('../../assets/images/navMenuLeft/yuyinchi-sel.png') 24px center no-repeat;
							background-size: 18px 18px;
							color: #517FFD;
						}
					}

					&.menu-title-huilinghao {
						background: url('../../assets/images/navMenuLeft/huilinghao.png') 24px center no-repeat;
						background-size: 18px 18px;

						&:hover,
						&.menu-title-act {
							background: url('../../assets/images/navMenuLeft/huilinghao-sel.png') 24px center no-repeat;
							background-size: 18px 18px;
							color: #517FFD;
						}
					}

					&.menu-title-supplier {
						background: url('../../assets/images/navMenuLeft/gongyingshang.png') 24px center no-repeat;
						background-size: 18px 18px;

						&:hover,
						&.menu-title-act {
							background: url('../../assets/images/navMenuLeft/gongyingshang-sel.png') 24px center no-repeat;
							background-size: 18px 18px;
							color: #517FFD;
						}
					}

					&.menu-title-channel {
						background: url('../../assets/images/navMenuLeft/qudao.png') 24px center no-repeat;
						background-size: 18px 18px;

						&:hover,
						&.menu-title-act {
							background: url('../../assets/images/navMenuLeft/qudao-sel.png') 24px center no-repeat;
							background-size: 18px 18px;
							color: #517FFD;
						}
					}

					&.menu-title-company {
						background: url('../../assets/images/navMenuLeft/qiye.png') 24px center no-repeat;
						background-size: 18px 18px;

						&:hover,
						&.menu-title-act {
							background: url('../../assets/images/navMenuLeft/qiye-sel.png') 24px center no-repeat;
							background-size: 18px 18px;
							color: #517FFD;
						}
					}

					&.menu-title-baobei {
						background: url('../../assets/images/navMenuLeft/baobei.png') 24px center no-repeat;
						background-size: 18px 18px;

						&:hover,
						&.menu-title-act {
							background: url('../../assets/images/navMenuLeft/baobei-sel.png') 24px center no-repeat;
							background-size: 18px 18px;
							color: #517FFD;
						}
					}

					&.menu-title-financeManage {
						background: url('../../assets/images/navMenuLeft/caiwu.png') 24px center no-repeat;
						background-size: 18px 18px;

						&:hover,
						&.menu-title-act {
							background: url('../../assets/images/navMenuLeft/caiwu-sel.png') 24px center no-repeat;
							background-size: 18px 18px;
							color: #517FFD;
						}
					}

					&.menu-title-cdrManage {
						background: url('../../assets/images/navMenuLeft/huadan.png') 24px center no-repeat;
						background-size: 18px 18px;

						&:hover,
						&.menu-title-act {
							background: url('../../assets/images/navMenuLeft/huadan-sel.png') 24px center no-repeat;
							background-size: 18px 18px;
							color: #517FFD;
						}
					}

					&.menu-title-systemManage {
						background: url('../../assets/images/navMenuLeft/settings.png') 24px center no-repeat;
						background-size: 18px 18px;

						&:hover,
						&.menu-title-act {
							background: url('../../assets/images/navMenuLeft/settings-sel.png') 24px center no-repeat;
							background-size: 18px 18px;
							color: #517FFD;
						}
					}
				}
			}
		}
	}
</style>