<template>
	<el-dialog :close-on-click-modal="false" append-to-body title="发展渠道" :visible.sync="mobileVisible" center width="500px" :before-close="handleClose">
		<div>
			<div class="title">通过识别码添加渠道</div>
			<div class="code_box">
				<div>我的供应商识别码：</div>
				<div class="code" @click="copyText">{{ identificationCode }}</div>
				<el-button type="text" @click="copyText(1)">复制识别码</el-button>
			</div>
		</div>
		<div>
			<div class="title">通过邀请链接添加渠道</div>
			<div class="code_box invitation_link">
				<el-button type="text" @click="copyText(2)">复制我的推广链接</el-button>
			</div>
		</div>
	</el-dialog>
</template>
<script>
	import service from '@/axios/request';
	export default {
		data() {
			return {
				mobileVisible: false,
				identificationCode: null
			}
		},
		methods: {
			// 打开弹窗
			handleOpen() {
				this.mobileVisible = true
				this.identificationCode = JSON.parse(localStorage.getItem('agent_info')).identity_code
			},
			// 关闭弹窗
			handleClose() {
				this.mobileVisible = false
			},
			//复制
			copyText(type) {
				let copyText = type == 1 ? this.identificationCode : `${ window.location.origin }/#/register?invitation_code=${this.identificationCode}`
				let input = document.createElement('input')
				input.value = copyText
				document.body.appendChild(input)
				input.select()
				document.execCommand('copy')
				document.body.removeChild(input)
				this.$message.success('复制成功')
			}
		},
	}
</script>
<style lang="scss" scoped>
	::v-deep  .el-dialog__body {
		padding: 20px 30px !important;
	}
	
	.title {
		font-size: 18px;
		padding: 20px 0 20px 10px;
		position: relative;
		box-sizing: border-box;
		font-weight: bold;
		display: flex;
		align-items: center;
	
		&::before {
			content: "";
			display: block;
			width: 3px;
			height: 20px;
			background: #0055ff;
			position: absolute;
			top: 50%;
			left: 0;
			transform: translate(-50%, -50%);
			box-sizing: border-box;
		}
	}
	
	.code_box {
		font-size: 14px;
		display: flex;
		align-items: center;
		padding: 0 20px;
		box-sizing: border-box;
		background: $themeBgColor;
		border-radius: 8px;
	}
	
	.code {
		font-weight: bold;
		cursor: pointer;
		margin-right: 10px;
	}
	
	.invitation_link {
		justify-content: center;
	}
</style>