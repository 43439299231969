<template>
    <div class="qs-account-box">
        <div class="qs-account-title" ref="qs-account-title">操作日志</div>
        <div class="qs-account-content">
            <div class="qs-search-box" ref="qs-search-box">
                <el-form :inline="true" ref="form" :model="form" label-width="80px" class="qs-demo-form-inline">
                    <div>
                        <el-form-item label="操作人" prop="user_id">
                            <el-select v-model="form.user_id" @change="init" :clearable="true" placeholder="请选择操作人">
                                <el-option v-for="(item, index) in options_contact_list" :label="item.user_name" :value="item.user_id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="操作时间">
                            <div class="qs-date-picker">
                                <el-date-picker v-model="date" type="daterange" format="yyyy-MM-dd" value-format="yyyy-MM-dd" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" @change="dateChange"></el-date-picker>
                            </div>
                        </el-form-item>
                        <el-form-item label="" class="qs-btn-box">
                            <el-button class="qs-search-btn" size="medium" type="primary" @click="init()">搜索</el-button>
                            <el-button class="qs-search-btn-reset" type="info" size="medium" @click="resetForm('form')">重置</el-button>
                        </el-form-item>
                    </div>
                </el-form>
            </div>
            <div class="qs-opration-box" ref="qs-opration-box">
                <el-button @click="toDo('', 'export')" class="qs-export-btn" type="default">导出报表</el-button>
                <div class="qs-total-result" v-loading="loading">总共：{{ total }}条结果</div>
            </div>
            <div class="qs-table">
                <el-table v-loading="loading" ref="multipleTable" stripe :data="tableData" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange">
                    <template slot="empty">
                        <empty v-if="emptyType=='initData'" ref="refEmpty"></empty>
                        <searchEmpty v-if="emptyType=='searchData'" ref="refsearchEmpty"></searchEmpty>
                    </template>
                    <el-table-column type="selection" width="50" align="right" fixed="left"></el-table-column>
                    <el-table-column prop="user_name" label="操作人" width="150"></el-table-column>
                    <el-table-column prop="content" label="操作内容"></el-table-column>
                    <el-table-column prop="ip" label="IP"></el-table-column>
                    <el-table-column prop="add_time" label="邀请时间"></el-table-column>
                </el-table>
                <div class="qs-table-pagination" ref="qs-table-pagination">
                    <el-pagination :current-page="page" :page-sizes="[10, 20, 50, 100]" :page-size="per_page" layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { tableHeightMixin } from '@/mixins/tableHeightMixin'
import { dateFormatter, downLoadExcel } from '@/utils/index'
import { mapState } from 'vuex'
import { getOperateLogList, exportOperateLog } from '@/api/system/index'
import empty from '@/components/empty.vue'
import searchEmpty from '@/components/searchEmpty.vue'
export default {
    mixins: [tableHeightMixin],
    components: { empty, searchEmpty },
    data() {
        return {
            form: {
                user_id: '',
                operate_start_time: '',
                operate_end_time: '',
            },
            date: '',
            loading: false,
            tableData: [],
            total: 0,
            page: 1,
            per_page: 10,
            select_id_arr: [],
            emptyType: 'initData',
        }
    },
    computed: {
        ...mapState({
            options_contact_list: (state) => state.common.options_contact_list,
        }),
    },
    mounted() {
        // this.initData()
        this.$nextTick(() => {
            this.tabelHeight([
                'qs-account-title',
                'qs-search-box',
                'qs-opration-box',
                'qs-table-pagination',
            ])
        })
    },
    methods: {
        dateChange(e) {
            if (e) {
                this.form.operate_start_time = e[0]
                this.form.operate_end_time = e[1]
            } else {
                this.date = ''
                this.form.operate_start_time = ''
                this.form.operate_end_time = ''
            }
            this.init()
        },
        init() {
            this.emptyType = 'searchData'
            this.page = 1
            this.per_page = 10
            this.initData()
        },
        initData() {
            let params = this.form
            params.page = this.page
            params.per_page = this.per_page
            getOperateLogList(params)
                .then((res) => {
                    if (res.code == '0000') {
                        this.tableData = res.data.item
                        this.tableData.forEach((item) => {
                            item.add_time = dateFormatter(item.add_time * 1000)
                        })
                        this.total = res.data.total
                    }
                })
                .catch(() => {})
        },
        handleSelectionChange(val) {
            if (val && val.length > 0) {
                val.forEach((item) => {
                    this.select_id_arr.push(item.log_id)
                })
            }
        },
        handleSizeChange(val) {
            this.per_page = val
            this.initData()
        },
        handleCurrentChange(val) {
            this.page = val
            this.initData()
        },
        toDo(row, oprate) {
            switch (oprate) {
                case 'export':
                    this.exportExcel()
                    break
                case 'delete':
                    this.deleteLog(row)
                    break
                default:
                    break
            }
        },
        deleteLog(row) {
            this.$confirm('确定删除该日志吗?', '提示', {
                confirmButtonText: '确 定',
                confirmButtonClass: 'confirmbtn', //自定义class
                cancelButtonText: '取 消',
                type: 'warning',
            })
                .then(() => {})
                .catch(() => {})
        },
        exportExcel() {
            let params = this.form
            params.log_id = this.select_id_arr.join(',')
            exportOperateLog(params).then((response) => {
                if (response.config.responseType === 'blob') {
                    let res =
                        response.headers['content-disposition'].match(
                            /filename="(\S*)"/
                        )
                    downLoadExcel(
                        response.data,
                        res && decodeURIComponent(res[1])
                    )
                }
            })
        },
        //重置
        resetForm(formName) {
            this.$refs[formName].resetFields()
            this.form = {
                user_id: '',
                operate_start_time: '',
                operate_end_time: '',
            }
            this.init()
        },
    },
}
</script>

<style lang="scss" scoped>
.qs-search-box {
    ::v-deep .el-form-item__label {
        width: 65px;
        color: #333;
        font-weight: initial;
    }
    ::v-deep .el-input__inner {
        width: 235px;
        height: 34px;
        line-height: 34px;
    }
    .qs-btn-box {
        margin-left: 8px;
        .qs-search-btn {
            background: #2469FF;
            color: #fff;
            border: none;
            &:hover {
                background: #3b7cff !important;
            }
        }
        .qs-search-btn-reset {
            &:hover {
                background: #ecf3ff;
                color: #666666;
            }
        }
    }
}
::v-deep .el-date-editor .el-range__icon {
    line-height: 27px;
}
::v-deep .el-date-editor .el-range-separator {
    line-height: 25px;
}
.qs-account-box {
    .qs-account-title {
        color: #333333;
        font-size: 16px;
        padding: 22px 24px;
        border-bottom: 1px solid #e3eef6;
        box-sizing: border-box;
    }
    .qs-account-content {
        box-sizing: border-box;
        padding: 24px;
        .qs-opration-box {
            padding: 15px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .qs-export-btn {
                background: #2469FF;
                color: #fff;
                border: none;
                &:hover {
                    background: #3b7cff !important;
                }
            }
            .qs-total-result {
                color: #0c2556;
                font-size: 14px;
            }
        }
        .qs-table {
            position: relative;
            ::v-deep .el-table td.el-table__cell,
            ::v-deep .el-table th.el-table__cell.is-leaf {
                border-bottom: none;
                margin-bottom: 5px;
            }
			::v-deep .el-table th.el-table__cell.is-leaf {
				&:first-child {
					.cell {
						box-sizing: border-box;
						padding-right: 14px;
					}
				}
			}
            .qs-delete {
                color: #333333;
                font-size: 14px;
                cursor: pointer;
            }
            .el-table {
                border-radius: 6px;
                &::before {
                    display: none;
                }
                .qs-label-detail {
                    color: #0d9d45 !important;
                    cursor: pointer;
                    font-size: 14px;
                }
                .qs-table-name {
                    color: #6874f2;
                    cursor: pointer;
                    position: relative;
                    &::before {
                        content: '';
                        display: none;
                        width: 14px;
                        height: 14px;
                        background: url('@/assets/images/edit.png') left center
                            no-repeat;
                        background-size: 100% 100%;
                        position: absolute;
                        top: 0;
                        left: -18px;
                    }
                    &:hover::before {
                        display: block;
                    }
                }
                .qs-table-opration-btn {
                    width: 14px;
                    height: 14px;
                    cursor: pointer;
                    position: relative;
                    .qs-table-oprations {
                        width: 140px;
                        height: 280px;
                        background: #ffffff;
                        box-shadow: 0px 0px 5px 1px rgba(220, 220, 220, 0.35);
                        border-radius: 6px;
                    }
                }
            }
        }
    }
}
</style>
