<template>
	<div :class="isZoom?'qs-header-menu-wrapper':'qs-header-menu-wrapper qs-header-menu-wrapper-nopad'">
		<Logo></Logo>
		<div class="header-box-shadow">
			<TopMenu :parentMenu="parentMenu"></TopMenu>
			<UserInfo></UserInfo>
		</div>
		<div class="breadcrumb-box">
			<div class="tab_box">
				<tabs ref="tabs"></tabs>
			</div>
			<div class="close-all-tabs" @click="closeAllTabs">关闭所有标签</div>
		</div>
	</div>
</template>
<script>
	import {
		mapState
	} from 'vuex';
	import Logo from '@/layout/components/menu/logo.vue'
	import TopMenu from '@/layout/components/menu/topMenu.vue'
	import UserInfo from '@/layout/components/menu/userInfo.vue'
	import tabs from '@/components/Tabs/index.vue'
	export default {
		name: 'headermenu',
		props: ['parentMenu'],
		computed: {
			...mapState({
				isZoom: (state) => state.layout.isZoom,
			})
		},
		components: {
			Logo,
			TopMenu,
			UserInfo,
			tabs
		},
		methods: {
			closeAllTabs() {
				this.$refs.tabs.closeAllTabs()
			}
		},
	}
</script>
<style lang="scss" scoped>
	.qs-header-menu-wrapper {
		padding-left: 235px;
		height: 108px;
		position: relative;
		background: #fff;
		transition: all 0.5s;

		.header-box-shadow {
			box-shadow: 0px 2px 6px 0px rgb(78 89 105 / 6%);
			margin-bottom: 0;
		}

		&.qs-header-menu-wrapper-nopad {
			padding-left: 0;
		}
	}

	.breadcrumb-box {
		padding: 0 15px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		background: #FCFCFC;

		.tab_box {
			width: calc(100% - 130px);
		}

		.close-all-tabs {
			width: 100px;
			text-align: center;
			font-size: 12px;
			height: 25px;
			line-height: 25px;
			border-radius: 4px;
			// border: 1px solid #cecece;
			color: #999999;
			cursor: pointer;

			&:hover {
				// border: 1px solid $themeColor;
				color: $themeColor;
			}
		}
	}
</style>