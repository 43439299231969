import vue from 'vue'
// 菜单列表
function menuList(data) {
	return vue.$get('/saas/api/menu/list', data)
}
// 登录接口
function login(data) {
	return vue.$post('/agent/Login/login', data)
}
//发送验证码 
function sendSmsCode(data) {
	return vue.$post('/agent/open/sendSmsCode', data)
}
// 获取图形验证码  
function getVerify(data) {
	return vue.$get('/agent/Login/getVerify', data)
}
// 退出登录 
function loginOut(data) {
	return vue.$post('/agent/Login/logout', data)
}
// 修改密码
function changePassword(data) {
	return vue.$post('/agent/User/changePassword', data)
}
// 用户列表  
function getUserlist(data) {
	return vue.$get('/agent/user/getList', data)
}
// 用户详情
function getUserinfo(data) {
	return vue.$get('/agent/user/getInfo', data)
}
// 新增用户 
function addUser(data) {
	return vue.$post('/agent/user/add', data)
}
// 编辑用户 
function editUser(data) {
	return vue.$post('/agent/user/edit', data)
}
// 重置用户密码
function resetPassword(data) {
	return vue.$post('/agent/user/resetPassword', data)
}
// 账户禁用
function userIsPause(data) {
	return vue.$post('/agent/user/isPause', data)
}
// 角色列表
function getRoleslist(data) {
	return vue.$get('/agent/role/getList', data)
}
//新增角色
function addRole(data) {
	return vue.$post('/agent/role/add', data)
}
//编辑角色
function editRole(data) {
	return vue.$post('/agent/role/edit', data)
}
//删除角色
function delRole(data) {
	return vue.$post('/agent/role/del', data)
}
//日志列表 
function getLogs(data) {
	return vue.$get('/agent/operateLog/getList', data)
}
//获取渠道详情配置  
function getAgentInfo(data) {
	return vue.$get('/agent/user/getAgentInfo', data)
}
//修改渠道信息  
function editAgent(data) {
	return vue.$post('/agent/user/editAgent', data)
}
//重置appkey   
function resetKey(data) {
	return vue.$post('/agent/user/resetKey', data)
}
// 渠道用户余额 
function getAgentBalance(data) {
	return vue.$get('/agent/user/getAgentBalance', data)
}
//获取渠道用户余额详情
function getAgentBalanceList(data) {
	return vue.$get('/agent/user/getAgentBalanceList', data)
}
//禁用/启用
function changePause(data) {
	return vue.$post('/agent/user/changePause', data)
}
//通过token获取用户信息
function getUserInfoToToken(data) {
	return vue.$get('/agent/login/getUserInfoToToken', data)
}
export {
	loginOut,
	menuList,
	login,
	getUserlist,
	getUserinfo,
	addUser,
	editUser,
	resetPassword,
	userIsPause,
	getRoleslist,
	addRole,
	editRole,
	delRole,
	sendSmsCode,
	getLogs,
	getVerify,
	getAgentInfo,
	editAgent,
	resetKey,
	changePassword,
	getAgentBalance,
	getAgentBalanceList,
	changePause,
	getUserInfoToToken
}