import vue from 'vue'

// 获取必填字段
function getRequireField (data) {
    return vue.$get('/saas/api/talent/getRequireField', data)
}
// 新增人才
function talentAdd (data) {
    return vue.$post('/saas/api/talent/add', data)
}
//编辑人才 
function talentEdit (data) {
    return vue.$post('/saas/api/talent/edit', data)
}
//校验邮箱重复 
function verifyEmailRepeat (data) {
    return vue.$post('/saas/api/talent/verifyEmailRepeat', data)
}
//获取所有人才
function getTalentList (data) {
    return vue.$get('/saas/api/talent/getList', data)
}
//获取人才详情
function getTalentInfo (data) {
    return vue.$get('/saas/api/talent/getInfo', data)
}
//查看人才详情
function checkTalentInfo (data) {
    return vue.$get('/saas/api/talent/checkInfo', data)
}
//获取备注列表 
function getRemarkList (data) {
    return vue.$get('/saas/api/talent/getRemarkList', data)
}
//保存备注 
function saveRemark (data) {
    return vue.$post('/saas/api/talent/saveRemark', data)
}
//删除备注 
function deleteRemark (data) {
    return vue.$post('/saas/api/talent/deleteRemark', data)
}
//关注人才 
function talentAttention (data) {
    return vue.$post('/saas/api/talent/attention', data)
}
//取消关注人才 
function cancelTalentAttention (data) {
    return vue.$post('/saas/api/talent/cancelAttention', data)
}
//放入回收站
function talentPutRecycle (data) {
    return vue.$post('/saas/api/talent/putRecycle', data)
}
//导出人才
function talentExport (data) {
    return vue.$download('get', '/saas/api/talent/export', data)
}
//获取收藏夹列表
function talentFavoritesList (data) {
    return vue.$get('/saas/api/talentFavorites/getList', data)
}
//获取收藏夹详情
function talentFavoritesInfo (data) {
    return vue.$get('/saas/api/talentFavorites/getInfo', data)
}
//新增收藏夹
function talentFavoritesAdd (data) {
    return vue.$post('/saas/api/talentFavorites/add', data)
}
//编辑收藏夹
function talentFavoritesEdit (data) {
    return vue.$post('/saas/api/talentFavorites/edit', data)
}
//删除收藏夹
function talentFavoritesDelete (data) {
    return vue.$post('/saas/api/talentFavorites/delete', data)
}
//加入收藏夹 
function joinFavorites (data) {
    return vue.$post('/saas/api/talent/joinFavorites', data)
}
//取消收藏夹
function cancelFavorites (data) {
    return vue.$post('/saas/api/talent/cancelFavorites', data)
}
//添加标签 
function saveTag (data) {
    return vue.$post('/saas/api/talent/saveTag', data)
}
//获取日志列表 
function getOperateLogList (data) {
    return vue.$get('/saas/api/talent/getOperateLogList', data)
}
//获取职位列表（进展中的职位）
function getJobList (data) {
    return vue.$get('/saas/api/talent/getJobList', data)
}
//加入职位 
function joinJob (data) {
    return vue.$post('/saas/api/talent/joinJob', data)
}
//更改状态 
function talentChangeStatus (data) {
    return vue.$post('/saas/api/talent/changeStatus', data)
}
//还原人才 
function talentRestore (data) {
    return vue.$post('/saas/api/talent/restore', data)
}
//删除人才 
function talentDelete (data) {
    return vue.$post('/saas/api/talent/delete', data)
}
//参与职位列表  
function getJoinJobList (data) {
    return vue.$get('/saas/api/talent/getJoinJobList', data)
}
//上传简历 
function uploadResume (data) {
    return vue.$postFormData('/saas/api/talent/uploadResume', data)
}
//粘贴简历 
function toPasteResume (data) {
    return vue.$post('/saas/api/talent/pasteResume', data)
}
//获取附件地址
function getAnnexPath (data) {
    return vue.$get('/saas/api/talent/getAnnexPath', data)
}
//查看附件 
function checkAnnex (data) {
    return vue.$get('/saas/api/talent/checkAnnex', data)
}
// 获取全网导入详情  
function getNetWorkTalentDetail (data) {
    return vue.$post('/saas/api/wholeNetwork/getTalentDetail', data)
}
export {
    getRequireField,
    talentAdd,
    talentEdit,
    verifyEmailRepeat,
    getTalentList,
    getTalentInfo,
    checkTalentInfo,
    getRemarkList,
    saveRemark,
    deleteRemark,
    talentAttention,
    cancelTalentAttention,
    talentDelete,
    talentRestore,
    talentPutRecycle,
    talentExport,
    talentFavoritesList,
    talentFavoritesInfo,
    talentFavoritesAdd,
    talentFavoritesEdit,
    talentFavoritesDelete,
    joinFavorites,
    cancelFavorites,
    getOperateLogList,
    saveTag,
    joinJob,
    getJobList,
    talentChangeStatus,
    getJoinJobList,
    uploadResume,
    toPasteResume,
    getAnnexPath,
    checkAnnex,
    getNetWorkTalentDetail
}
