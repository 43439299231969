<template>
	<div class="qs-app-main-wrapper" :class="{ 'qs-app-main-wrapper-ac': !isShowZoom }">
		<router-view :key="key" />
	</div>
</template>

<script>
	import {
		mapState,
		mapMutations
	} from 'vuex'

	export default {
		name: 'appMain',
		components: {
			// tabs
		},
		computed: {
			...mapState({
				isSideBar: (state) => state.layout.isSideBar,
				isZoom: (state) => state.layout.isZoom,
			}),
			isShowZoom() {
				if (this.isSideBar) {
					return this.isZoom
				} else {
					return false
				}
			},
			key() {
				return this.$route.path + Math.random()
			},
		},
	}
</script>

<style lang="scss" scoped>
	.qs-app-main-wrapper {
		width: calc(100% - 235px);
		background: #f6f9ff;
		height: 100%;
		padding: 10px;
		transform: translateX(235px);
		transition: all 0.5s;
		overflow-y: scroll;

		&.qs-app-main-wrapper-ac {
			transform: translateX(0);
			width: 100%;
		}
	}
</style>