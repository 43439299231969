const throttle = {
    data () {
        return {
            isor: true
        }
    },
    methods: {
        _debounce (func, delay) {
            var setTimeOut
            if (!this.isor) {
                clearTimeout(setTimeOut)
            } else {
                this.isor = false
                setTimeOut = setTimeout(() => {
                    typeof func === "function" ? func() : ""
                    this.isor = true
                }, delay)
                return
            }
        }

    }
}
export default throttle
