export const tableHeightMixin = {
	data() {
		return {
			tableH: 0
		}
	},
	computed: {
		tabelHeight() {
			return (val) => {
				this.$nextTick(() => {
					const docHeight = document.documentElement.clientHeight;
					let eleList = val
					let eleTotalHeight = 0
					eleList.forEach((item) => {
						eleTotalHeight += this.$refs[item].clientHeight
					})
					//10-固定上边距  104-固定header高度
					let tabelHeight = docHeight - eleTotalHeight - 230;
					this.tableH = tabelHeight
				});
			}
		}
	},
	mounted() {},
	methods: {},
}