<template>
	<div>
		<el-table ref="dataTable" :data="tableData" v-loading="loading" stripe class="tableStyle" :max-height="maxHeight" @selection-change="handleSelectionChange" fit border lazy>
			<slot name="select"></slot>
			<template v-for="(item, index) in tableHead">
				<el-table-column v-if="tableHead[index].isShow" :key="index" :prop="item.columnName" :label="item.columnTitle" :width="item.width" :show-overflow-tooltip="item.isTooltip">
					<template slot-scope="scope">
						<div>{{ scope.row[item.columnName] }}</div>
					</template>
				</el-table-column>
			</template>
			<slot name="operate"></slot>
			<!-- 切换显示隐藏列 -->
			<el-table-column width="40">
				<template slot="header" slot-scope="scope">
					<a href="javascript:;">
						<el-popover :ref="`popover-${scope.$index}`" width="150" placement="left" trigger="click">
							<el-checkbox-group v-model="columnSelecteds">
								<el-checkbox v-for="item in tableHead" v-show="item.columnTitle" :key="item.columnTitle" :label="item.columnTitle" />
							</el-checkbox-group>
							<i slot="reference" class="el-icon-s-grid" />
						</el-popover>
					</a>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>
<script>
	export default {
		props: {
			tableData: {
				type: Array,
				required: true,
				default: () => []
			},
			tableHead: {
				type: Array,
				required: true,
				default: () => []
			},
			maxHeight: {
				type: Number,
				default: () => 500
			},
			loading: {
				type: Boolean,
				default: () => false
			}
		},
		data() {
			return {
				columnSelecteds: [] // 已选择的项
			}
		},
		watch: {
			/* @title 监听列显示隐藏**/
			columnSelecteds(newArrayVal) {
				// 计算为被选中的列标题数组
				var nonSelecteds = this.tableHead.filter((item) => newArrayVal.indexOf(item.columnTitle) === -1).map((item) => item.columnTitle)
				// 根据计算结果进行表格重绘
				this.tableHead.filter((item) => {
					const isNonSelected = nonSelecteds.indexOf(item.columnTitle) !== -1
					if (isNonSelected) {
						// 隐藏未选中的列
						item.isShow = false
						this.$nextTick(() => {
							this.$refs.dataTable.doLayout()
						})
					} else {
						// 显示已选中的列
						item.isShow = true
						this.$nextTick(() => {
							this.$refs.dataTable.doLayout()
						})
					}
				})
				localStorage.setItem('columnSelecteds', JSON.stringify(newArrayVal))
			}
		},
		created() {
			this.tableHead.forEach((item) => {
				if (item.isShow) {
					this.columnSelecteds.push(item.columnTitle)
				}
			})
			// // 初始化要显示的列
			// if (JSON.parse(localStorage.getItem('columnSelecteds')) && JSON.parse(localStorage.getItem('columnSelecteds')).length) {
			// 	this.columnSelecteds = JSON.parse(localStorage.getItem('columnSelecteds'))
			// } else {
			// 	this.tableHead.forEach((item) => {
			// 		if (item.isShow) {
			// 			this.columnSelecteds.push(item.columnTitle)
			// 		}
			// 	})
			// 	localStorage.setItem('columnSelecteds', JSON.stringify(this.columnSelecteds))
			// }
		},
		methods: {
			// 选中行
			handleSelectionChange(val) {
				this.$emit('handleSelectionChange', val)
			}
		}
	}
</script>
<style lang="scss" scoped>
	::v-deep .headerCell {
		padding: 5px 0;
		background: #f5f7fa;
		color: #606266;
	}

	::v-deep .contentCell {
		padding: 5px 0;
		text-align: center;
	}

	::v-deep .el-table .cell {
		white-space: nowrap;
	}

	.el-checkbox:last-of-type {
		margin-right: 30px;
	}
</style>